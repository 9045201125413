import React from 'react';
import Script from 'next/script';
import Markdown from 'react-markdown';
import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { Theme, ThemeProps } from '@theme/base';
import { SectionSeparator } from '@styles/layout';
import { breakpoints, mq } from '@styles/breakpoints';
import { mixins } from '@styles/mixins';
import { Footer as FooterModel } from '@model/content';
import { useI18NextContext } from '@components/hooks';
import { OpeningTimesTable } from '@components/common/opening-times';
import { TestId } from '@components/test-ids';
import { NewsletterSignUpFormContainer } from '@components/newsletter-signup/NewsletterSignUpFormContainer';
import { LazyLoadBackgroundImage } from '@components/common/lazy-load';
import { TrustpilotWidget } from '@components/common/trustpilot-widget';
import { MaxContentWidth } from '@components/common/layout/max-content-width';
import { clickToCallWrapper } from '@components/common/r-tap-number';
import { LinkComponent } from '@components/common/description/TextMarkdown';
import { TextMarkdown } from '@components/common/description';
import { AbtaAtol } from './AbtaAtol';
import { Links } from '../links';
import { getImageUrl } from '@util/images';

export interface FooterProps extends FooterModel {
  hasBasketPanel: boolean;
  hideSignUp?: boolean;
  hideReviews?: boolean;
  isWhiteLabel: boolean;
}

const Container: any = styled.footer({
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  flexDirection: 'column',
  width: '100%',

  [mq.small]: {
    paddingBottom: 0
  }
});

const InformationContainer = styled.div({
  display: 'flex',
  justifyContent: 'flex-start',
  flexDirection: 'column',
  height: '100%',
  zIndex: 1,
  [mq.small]: {
    flexDirection: 'row',
    justifyContent: 'space-between'
  }
});

const ContactContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  [mq.small]: {
    textAlign: 'right'
  }
});

const ContactNumber = styled.div(({ theme }: ThemeProps) => ({
  marginTop: theme.custom.spacing.xLarge,
  marginBottom: theme.custom.spacing.xxLarge,
  ...mixins(theme).textShadow,
  textDecoration: 'none',
  transition: `color ${theme.custom.transitions.default}`,
  [mq.small]: {
    marginTop: 0
  },
  color: theme.custom.colors.white,
  '&:active': {
    color: theme.custom.colors.white
  },
  '&:hover': {
    color: theme.custom.colors.group9.base,
    cursor: 'pointer'
  },
  p: {
    ...(theme.custom.typography.h4 as any),
    margin: 0,
    a: {
      color: theme.custom.colors.white
    }
  }
}));

const BackgroundImage = styled(LazyLoadBackgroundImage)({
  zIndex: 0,
  objectPosition: '70%'
});

interface BackgroundProps extends ThemeProps {
  hasBasketPanel: boolean;
}
const Background: any = styled.div(({ theme, hasBasketPanel }: BackgroundProps) => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'flex-end',
  position: 'relative',
  flex: 1,
  paddingTop: theme.custom.spacing.xxxLarge + theme.custom.spacing.xxLarge,
  paddingBottom: hasBasketPanel ? theme.custom.spacing.xxxLarge : 0,

  [mq.small]: {
    backgroundPosition: 'top',
    paddingTop: 5 * theme.custom.spacing.xxxLarge
  },

  [mq.medium]: {
    paddingBottom: 0
  }
}));

export const MaxContentWidthStyled = styled(MaxContentWidth)(({ theme }: ThemeProps) => ({
  padding: `0 ${theme.custom.spacing.large}px`,
  zIndex: 1
}));

const ReviewContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  [mq.medium]: {
    flexDirection: 'row'
  }
});

export const Logos: any = styled.div(({ theme }: ThemeProps) => ({
  marginTop: theme.custom.spacing.xxLarge,
  marginBottom: theme.custom.spacing.xxLarge,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  [mq.small]: {
    justifyContent: 'flex-end',
    flexDirection: 'row',
    marginTop: theme.custom.spacing.xxLarge,
    marginBottom: theme.custom.spacing.xxLarge
  }
}));

const TravelAdvice = styled.div(({ theme }: ThemeProps) => ({
  marginTop: theme.custom.spacing.xxLarge,
  ...mixins(theme).textShadow,
  color: theme.custom.colors.white,
  ...(theme.custom.typography.paragraph as any),
  [mq.medium]: {
    marginBottom: theme.custom.spacing.xxLarge,
    marginRight: theme.custom.spacing.xxLarge
  },
  p: {
    margin: 0
  },
  a: {
    ...(theme.custom.typography.paragraph as any),
    ...mixins(theme).textShadow,
    ...mixins(theme).truncate,
    color: theme.custom.colors.white,
    transition: `color ${theme.custom.transitions.default}`,
    cursor: 'pointer',
    userSelect: 'none',
    textDecoration: 'underline',
    ['&:visited, &:active']: {
      color: theme.custom.colors.white
    },
    ['&:hover, &:visited:hover, &:active:hover']: {
      color: theme.custom.colors.group9.base
    }
  }
}));

export const MainFooter = ({
  links,
  openingTimes,
  phoneNumber,
  hasBasketPanel,
  isWhiteLabel,
  travelAdvice,
  logo,
  hideSignUp,
  hideReviews
}: FooterProps) => {
  const t = useI18NextContext();
  const { openingTimeDays = [], openingTimeHours = [], altTitle: openingTimesTitle } = openingTimes || {};

  const theme: Theme = useTheme();
  const { url, title } = logo;

  return (
    <Container data-testid={TestId.footer.mainFooter.main}>
      {!isWhiteLabel && !hideReviews && (
        <MaxContentWidthStyled>
          <Script
            type="text/javascript"
            src="https://widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js"
            strategy="lazyOnload"
          />
          <TrustpilotWidget />
          <SectionSeparator size={theme.custom.spacing.xxLarge} />
        </MaxContentWidthStyled>
      )}
      {!isWhiteLabel && !hideSignUp && (
        <MaxContentWidthStyled>
          <NewsletterSignUpFormContainer />
        </MaxContentWidthStyled>
      )}
      <Background hasBasketPanel={hasBasketPanel}>
        <BackgroundImage src={'/assets/images/footer/footer.jpg'} />
        <MaxContentWidthStyled>
          <InformationContainer>
            <Links links={links} testId={TestId.footer.mainFooter.linksContainer} isWhiteLabel={isWhiteLabel} />
            <ContactContainer data-testid={TestId.footer.mainFooter.contactContainer}>
              <ContactNumber onClick={clickToCallWrapper}>
                <Markdown children={t(phoneNumber)} components={LinkComponent} />
              </ContactNumber>
              <OpeningTimesTable days={openingTimeDays} hours={openingTimeHours} title={openingTimesTitle} />
            </ContactContainer>
          </InformationContainer>
          <ReviewContainer>
            {travelAdvice && (
              <TravelAdvice>
                <TextMarkdown description={travelAdvice} />
              </TravelAdvice>
            )}
            {logo && (
              <Logos data-testid={TestId.footer.mainFooter.logos} data-filename={title}>
                <AbtaAtol src={getImageUrl(url, breakpoints.small, true)} alt={t('common__label--abta-atol')} />
              </Logos>
            )}
          </ReviewContainer>
        </MaxContentWidthStyled>
      </Background>
    </Container>
  );
};
