import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { AppVariant } from '@model/config/brands';
import { Footer as FooterModel, FooterTypes } from '@model/content';
import { Basket } from '@model/iceberg';
import { withIsServer } from '@components/hoc';
import { MainFooter } from './main';
import { CheckoutFooter } from './checkout';
import { getBasket } from '@state/basket';
import { getFooter } from '@state/content';
import { BASKET_PANEL_ID } from '@components/basket/v2/basket-panel';

interface FooterProps {
  type?: FooterTypes;
  isServer: boolean;
  hideSignUp?: boolean;
  hideReviews?: boolean;
}

export const Footer = withIsServer((props: FooterProps) => {
  const { type = FooterTypes.MAIN, isServer } = props;

  const basket: Basket = useSelector(getBasket);
  const footer: FooterModel | null = useSelector(getFooter(type));
  const isWhiteLabel = AppVariant.isWhitelabel();

  /* *** STATE *** */
  const [hasBasketPanel, setHasBasketPanel] = useState<boolean>(false);

  /* *** HOOKS *** */
  useEffect(() => {
    setHasBasketPanel(!isServer && !!document.getElementById(BASKET_PANEL_ID));
  }, [basket, isServer]);

  if (!footer) return null;

  return type === FooterTypes.CHECKOUT ? (
    <CheckoutFooter {...footer} hasBasketPanel={hasBasketPanel} {...props} />
  ) : (
    <MainFooter {...footer} isWhiteLabel={isWhiteLabel} hasBasketPanel={hasBasketPanel} {...props} />
  );
});
